.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
svg {
  shape-rendering: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.round {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-color: #669581;
}
.round-white{
  width: 100%;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-color: #fff;
}

.round::before,.round-white::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 250%;
  height: 100%;
  z-index: 0;
  border-radius: 50% 50% 0% 0%;
  transform: translateX(-50%) rotate(0deg);
  background-color: #DBD3D8;
}

.content {
  position: relative;
  overflow: hidden;
  line-height: 40px;
  color: #ecf0f1;
  display: flex;
  justify-content: flex-start;
}
.content::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  z-index: 1;
  display: block;
  content: "";
}
.content::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 5px;
  width: 100%;
  z-index: 1;
  display: block;
  content: "";
}
.content__container {
  overflow: hidden;
  height: 45px;
}
.content__container__text {
  margin: 0;
}

.content__container__list {
  margin-top: 0;
  text-align: left;
  list-style: none;
  padding: 0;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  padding-bottom: 3px;
}
.content__container__list__item {
  line-height: 25px;
  margin: 0;
}
@media (min-width: 768px) {
  .content__container__list__item {
    line-height: 40px;
    margin: 0;
  }
  .content__container {
    overflow: hidden;
    height: 60px;
  }
}
.content__container__list__item#first {
  color: #669581;
}
.content__container__list__item#second {
  color: #669581;
}
.content__container__list__item#third {
  color: #669581;
}


@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0% {
    transform: translate3d(0, 25%, 0);
    opacity: 0.3;
  }
  5.32%, 25%{
    transform: translate3d(0, -4%, 0);
    opacity: 1;
  }
  35.66%,
  60.32% {
    transform: translate3d(0, -38%, 0);
  }
  70.98%,
  95.34% {
    transform: translate3d(0, -70%, 0);
  }
  97.5%,
  100% {
    transform: translate3d(0, -100%, 0);
    opacity: 0.8;
  }
}
@keyframes Phones {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
    transform: rotate(15deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    transform: rotate(0deg);
  }
}
#phone_3 {
  opacity: 0;
}
#phone_2 {
  opacity: 0;
}
#phone_1 {
  opacity: 0;
}
#phonesvg.active #phone_3 {
  animation: Phones 0.6s 0.5s ease-in-out 1 alternate both;
}
#phonesvg.active #phone_2 {
  animation: Phones 0.6s 0.7s ease-in-out 1 alternate both;
}
#phonesvg.active #phone_1 {
  animation: Phones 0.6s 1s ease-in-out 1 alternate both;
}
